import { ReactComponent as DataIcon } from 'assets/icons/dataIcon.svg';
import { ReactComponent as ConfigurationIcon } from 'assets/icons/configurationIcon.svg';
import { ReactComponent as DataExplorer } from 'assets/icons/dataExplorer.svg';
import { ReactComponent as UserManagementIcon } from 'assets/icons/userManagement.svg';
import { ReactComponent as DeviceConnectionIcon } from 'assets/icons/deviceConnection.svg';
// import { ReactComponent as BusinessConnectionIcon } from 'assets/icons/businessConnection.svg';
// import { ReactComponent as AddNewDevicesIcon } from 'assets/icons/addNewDevices.svg';
import { ReactComponent as DataTablesIcon } from 'assets/icons/dataTables.svg';
import { ReactComponent as DeviceListIcon } from 'assets/icons/deviceList.svg';
import { ReactComponent as UserIcon } from 'assets/icons/userIcon.svg';
import { ReactComponent as RoleIcon } from 'assets/icons/rolesIcon.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
// import { ReactComponent as FillingPackagingIcon } from 'assets/icons/fillingPackagingIcon.svg';
import { ReactComponent as DataVisualization } from 'assets/icons/dataVisualization.svg';
import { ReactComponent as DashboardReporting } from 'assets/icons/dashboardReportSectionIcon.svg';
import { ReactComponent as DashboardBuilder } from 'assets/icons/dashboardBuilderIcon.svg';
import { ReactComponent as ReportHeader } from 'assets/icons/reportingHeader.svg';
import { ReactComponent as ReportConfigurator } from 'assets/icons/reportConfiguratorIcon.svg';
import { ReactComponent as UseCases } from 'assets/icons/useCases.svg';
import { ReactComponent as BoilerEfficiencyMonitoring } from 'assets/icons/boilerEfficiencyMonitoring.svg';
import { ReactComponent as NocilDashboard } from 'assets/icons/nocilDashboard.svg';
import { ReactComponent as ManualEntry } from 'assets/icons/manualEntry.svg';
import { ReactComponent as OperatorEntry } from 'assets/icons/operatorEntry.svg';
import { ReactComponent as Groups } from 'assets/icons/groups.svg';
import { ReactComponent as Settings } from 'assets/icons/sideNavSettingsIcon.svg';
import { ReactComponent as ConfigIcon } from 'assets/icons/config.svg';

export const data = [
    {
        name: 'Home',
        breadcrumb: 'Home',
        key: 'Home',
        icon: <HomeIcon />,
        path: '/home',
        exact: true,
    },
    {
        id: 71,
        name: 'Connect',
        breadcrumb: 'Connect',
        key: 'Connect',
        icon: <DataIcon />,
        path: '/device-management',
        exact: true,
        children: [
            {
                id: 70,
                name: 'Device Management',
                breadcrumb: 'Device Management',
                key: 'Device Management',
                icon: <DeviceConnectionIcon />,
                path: '/device-connection',
                exact: true,
                children: [
                    {
                        id: 4,
                        name: 'BLAs',
                        breadcrumb: 'BLAs',
                        key: 'BLA',
                        icon: <DeviceListIcon />,
                        path: '/device-management/blas',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 5,
                        name: 'Devices',
                        breadcrumb: 'Devices',
                        key: 'Devices',
                        icon: <DeviceListIcon />,
                        path: '/device-management/devices',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 6,
                        name: 'Tags',
                        breadcrumb: 'Tags',
                        key: 'Tags',
                        icon: <DeviceListIcon />,
                        path: '/device-management/tags',
                        exact: true,
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        id: 165,
        name: 'Configure',
        breadcrumb: 'Configure',
        key: 'Configure',
        icon: <ConfigIcon />,
        path: '/configure',
        exact: true,
        children: [
            {
                id: 166,
                name: 'Data Dictionary',
                breadcrumb: 'Data Dictionary',
                key: 'Data-Dictionary',
                icon: <DeviceConnectionIcon />,
                path: '/configure/data-dictionary',
                exact: true,
                children: [
                    {
                        id: 167,
                        name: 'Attribute',
                        breadcrumb: 'Attribute',
                        key: 'Attribute',
                        icon: <DeviceListIcon />,
                        path: '/configure/data-dictionary/attribute',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 168,
                        name: 'Time Capsule',
                        breadcrumb: 'Time Capsule',
                        key: 'Time Capsule',
                        icon: <DeviceListIcon />,
                        path: '/configure/data-dictionary/time-Capsule',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 169,
                        name: 'Table',
                        breadcrumb: 'Table',
                        key: 'Table',
                        icon: <DeviceListIcon />,
                        path: '/configure/data-dictionary/table',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 170,
                        name: 'Alert',
                        breadcrumb: 'Alert',
                        key: 'Alert',
                        icon: <DeviceListIcon />,
                        path: '/configure/data-dictionary/alert',
                        exact: true,
                        children: [],
                    },
                ],
            },
        ],
    },
    // will use later
    // {
    //     id: 69,
    //     name: 'Transform',
    //     breadcrumb: 'Transform',
    //     key: 'Transform',
    //     icon: <DataIcon />,
    //     path: '/transform',
    //     exact: true,
    //     children: [
    //         {
    //             id: 68,
    //             name: 'Contextualization',
    //             breadcrumb: 'Contextualization',
    //             key: 'Contextualization',
    //             icon: <DeviceConnectionIcon />,
    //             exact: true,
    //             children: [
    //                 {
    //                     id: 7,
    //                     name: 'Asset Modeler',
    //                     breadcrumb: 'Asset Modeler',
    //                     key: 'asset-modeler',
    //                     icon: <DeviceListIcon />,
    //                     path: '/asset-modeler',
    //                     exact: true,
    //                 },
    //                 {
    //                     id: 8,
    //                     name: 'Custom Context',
    //                     breadcrumb: 'Custom Context',
    //                     key: 'custom-context',
    //                     icon: <AddNewDevicesIcon />,
    //                     path: '/custom-context',
    //                     exact: true,
    //                 },
    //             ],
    //         },
    //         {
    //             id: 67,
    //             name: 'Custom Events Processing (CEP)',
    //             breadcrumb: 'Custom Events Processing (CEP)',
    //             key: 'Custom-events-processing',
    //             icon: <DeviceConnectionIcon />,
    //             exact: true,
    //         },
    //     ],
    // },
    // {
    //     id: 66,
    //     name: 'Configure',
    //     breadcrumb: 'Configure',
    //     key: 'Configure',
    //     icon: <ConfigurationIcon />,
    //     path: '/configure',
    //     exact: true,
    //     children: [
    //         {
    //             id: 65,
    //             name: 'KPIs',
    //             breadcrumb: 'KPIs',
    //             key: 'kpis',
    //             icon: <DeviceListIcon />,
    //             path: '/kpis',
    //             exact: true,
    //         },
    //         {
    //             id: 64,
    //             name: 'Dashboards ',
    //             breadcrumb: 'Dashboards ',
    //             key: 'dashboards',
    //             icon: <DeviceListIcon />,
    //             path: '/dashboards ',
    //             exact: true,
    //         },
    //         {
    //             id: 63,
    //             name: 'Reports',
    //             breadcrumb: 'Reports',
    //             key: 'reports',
    //             icon: <DeviceListIcon />,
    //             path: '/reports',
    //             exact: true,
    //         },
    //         {
    //             id: 62,
    //             name: 'Forms',
    //             breadcrumb: 'Forms',
    //             key: 'forms',
    //             icon: <DeviceListIcon />,
    //             path: '/forms',
    //             exact: true,
    //         },
    //     ],
    // },
    // {
    //     id: 61,
    //     name: 'Automate',
    //     breadcrumb: 'Automate',
    //     key: 'automate',
    //     icon: <AutomationIcon />,
    //     path: '/automate',
    //     exact: true,
    //     children: [
    //         {
    //             id: 60,
    //             name: 'Workflows',
    //             breadcrumb: 'Workflows',
    //             key: 'workflows',
    //             icon: <DeviceListIcon />,
    //             path: '/workflows',
    //             exact: true,
    //         },
    //         {
    //             id: 59,
    //             name: 'Notifications',
    //             breadcrumb: 'Notifications',
    //             key: 'notifications',
    //             icon: <DeviceListIcon />,
    //             path: '/notifications',
    //             exact: true,
    //         },
    //     ],
    // },
    {
        id: 58,
        name: 'Data Explorer',
        breadcrumb: 'Data Explorer',
        key: 'Data Explorer',
        icon: <DataExplorer />,
        exact: true,
        children: [
            {
                id: 11,
                name: 'Data Visualization',
                breadcrumb: 'Data Visualization',
                key: 'Data Visualization',
                icon: <DataVisualization />,
                path: '/data-visualization',
                exact: true,
                children: [
                    {
                        id: 162,
                        name: 'Trending',
                        breadcrumb: 'Trending',
                        key: 'Trending',
                        icon: <ConfigurationIcon />,
                        path: '/data-visualization/trending',
                        exact: true,
                    },
                    {
                        id: 163,
                        name: 'Data Table',
                        breadcrumb: 'Data Table',
                        key: 'Data Table',
                        icon: <DataTablesIcon />,
                        path: '/data-visualization/data-table',
                        exact: true,
                    },
                ],
            },
            {
                id: 15,
                name: 'Dashboard Builder',
                breadcrumb: 'Dashboard Builder',
                key: 'Dashboard Builder',
                icon: <DashboardBuilder />,
                path: '/dashboard-builder',
                exact: true,
                children: [],
            },
            {
                id: 55,
                name: 'Dashboard Reporting',
                breadcrumb: 'Dashboard Reporting',
                key: 'Dashboard Reporting',
                icon: <ReportHeader />,
                exact: true,
                children: [
                    {
                        id: 56,
                        name: 'Report Configurator',
                        breadcrumb: 'Report Configurator',
                        key: 'Report Configuration',
                        icon: <ReportConfigurator />,
                        path: '/report-configurator',
                        exact: true,
                    },
                    {
                        id: 16,
                        name: 'Reports',
                        breadcrumb: 'Reports',
                        key: 'Reports',
                        icon: <DashboardReporting />,
                        path: '/reports',
                        exact: true,
                    },
                ],
            },
        ],
    },
    {
        id: 57,
        name: 'Use Cases',
        breadcrumb: 'Use Cases',
        key: 'Use Cases',
        icon: <UseCases />,
        exact: true,
        children: [
            // will use later
            // {
            //     id: 56,
            //     name: 'CPG - OEE',
            //     breadcrumb: 'CPG - OEE',
            //     key: 'upl',
            //     // icon: <FillingPackagingIcon />,
            //     exact: true,
            //     children: [],
            // },
            // {
            //     id: 55,
            //     name: 'Chemical Process Monitoring',
            //     breadcrumb: 'Chemical Process Monitoring',
            //     key: 'aarti_apple',
            //     icon: <UserIcon />,
            //     exact: true,
            //     children: [],
            // },
            {
                id: 54,
                name: 'More Configurations',
                breadcrumb: 'More Configurations',
                key: 'Brabo',
                icon: <UserIcon />,
                exact: true,
                children: [],
            },
            // {
            //     id: 53,
            //     name: 'Critical Asset Monitoring',
            //     breadcrumb: 'Critical Asset Monitoring',
            //     key: 'eberspacher',
            //     icon: <UserIcon />,
            //     path: '/use-cases/robotic-arm',
            //     exact: true,
            //     children: [],
            // },
            {
                id: 52,
                name: 'Boiler Industries',
                breadcrumb: 'nocil',
                key: 'Boiler Efficiency',
                icon: <BoilerEfficiencyMonitoring />,
                // path: '/nocil/boiler-efficiency',
                exact: true,
                children: [
                    {
                        id: 9,
                        name: 'Boiler Dashboard',
                        breadcrumb: 'nocil',
                        key: 'NOCIL Dashboard',
                        icon: <NocilDashboard />,
                        path: '/boiler/boiler-dashboard',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 10,
                        name: 'Manual Entry',
                        breadcrumb: 'nocil',
                        key: 'Manual Entry',
                        icon: <ManualEntry />,
                        path: '/boiler/manual-entry',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 13,
                        name: 'Operator Entry',
                        breadcrumb: 'nocil',
                        key: 'Operator Entry',
                        icon: <OperatorEntry />,
                        path: '/boiler/operator-entry',
                        exact: true,
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        id: 51,
        name: 'User Management',
        breadcrumb: 'User Management',
        key: 'User Management',
        icon: <UserManagementIcon />,
        exact: true,
        children: [
            {
                id: 1,
                name: 'Users',
                breadcrumb: 'Users',
                key: 'Users',
                icon: <UserIcon />,
                path: '/user-management/users',
                exact: true,
                children: [],
            },
            {
                id: 2,
                name: 'Groups',
                breadcrumb: 'Groups',
                key: 'Resource Groups',
                icon: <Groups />,
                path: '/user-management/groups',
                exact: true,
                children: [],
            },
            {
                id: 3,
                name: 'Roles',
                breadcrumb: 'Roles',
                key: 'Roles',
                icon: <RoleIcon />,
                path: '/user-management/roles',
                exact: true,
                children: [],
            },
        ],
    },
    {
        id: 50,
        name: 'Settings',
        breadcrumb: 'Settings',
        key: 'Settings',
        icon: <Settings />,
        path: '/settings',
        exact: true,
        children: [
            {
                id: 12,
                name: 'Calendar Configurator',
                breadcrumb: 'Calendar Configurator',
                key: 'Calendar Configuration',
                path: '/settings/calendar-configurator',
                exact: true,
                children: [],
            },
        ],
    },
];
